import './SettingsPrivacyContent.less'

import { Button } from 'antd'
import { ContentState } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { useScopedIntl } from '../../../../hooks'
import { CustomConsent, deleteCustomConsent, fetchCustomConsents, updateCustomConsent } from '../../../../requests'
import { DatacIcon, DatacLoading, DatacMessage, DatacTitle } from '../../../common'
import { EditorOutput } from '../../../shared/Editor'
import { SettingsLayout } from '../../SettingsLayout'
import { SettingsPrivacyConsentModal } from './SettingsPrivacyConsentModal'

const maxConditions = 5

export const SettingsPrivacyContent: React.FC = () => {
  const intl = useScopedIntl('')
  const intlPrivacy = useScopedIntl('settings.privacy')
  const [consents, setConsents] = useState<CustomConsent[]>([])
  const [isFetchingConsents, setIsFetchingConsents] = useState(false)
  const [consentToView, setConsentsToView] = useState<CustomConsent>(null)
  const [isAddModalOpened, setIsAddModalOpened] = useState(false)

  useEffect(() => {
    fetchConsents()
  }, [])

  const fetchConsents = () => {
    setIsFetchingConsents(true)
    fetchCustomConsents(
      {},
      {
        onSuccess: consents => {
          setConsents(consents)
          setIsFetchingConsents(false)
        },
        onRequestError: code => {
          setIsFetchingConsents(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onDelete = (id: number) => {
    setIsFetchingConsents(true)
    deleteCustomConsent(
      { id },
      {
        onSuccess: () => fetchConsents(),
        onRequestError: code => {
          setIsFetchingConsents(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onModalClose = () => {
    setIsAddModalOpened(false)
    setConsentsToView(null)
    fetchConsents()
  }

  const DragHandle = SortableHandle(({ consent }: { consent: CustomConsent }) => (
    <div className="settings-privacy-content__consent__handle">
      <DatacIcon name="menu" type="grey" /> <EditorOutput content={consent.body as ContentState} />
    </div>
  ))

  const SortableItem = SortableElement(({ consent }: { consent: CustomConsent }) => (
    <li className="settings-privacy-content__consent">
      <DragHandle consent={consent} />
      <DatacIcon name="edit" type="blue" onClick={() => setConsentsToView(consent)} />
      <DatacIcon name="trash" type="red" onClick={() => onDelete(consent.id)} />
    </li>
  ))

  const SortableList = SortableContainer(({ consents }: { consents: CustomConsent[] }) => (
    <ul>
      {consents.map((consent, index) => (
        <SortableItem key={consent.id} index={index} consent={consent} />
      ))}
    </ul>
  ))

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setIsFetchingConsents(true)
    updateCustomConsent(
      { ...consents[oldIndex], order: newIndex },
      {
        onSuccess: () => {
          fetchConsents()
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingConsents(false)
        }
      }
    )
  }

  return (
    <SettingsLayout>
      <div className="settings-privacy-content">
        <div className="settings-privacy-content__header">
          <div>
            <DatacTitle type="h2">{intlPrivacy('title')}</DatacTitle>
            <div className="settings-privacy-content__header__description">{intlPrivacy('description')}</div>
          </div>
          {consents?.length < maxConditions && (
            <Button
              className="settings-privacy-content__header-button settings-privacy-content__add-button"
              type="primary"
              size="large"
              onClick={() => setIsAddModalOpened(true)}
            >
              <DatacIcon type="white" name="plus" size="big" />
              {intlPrivacy('add_consent')}
            </Button>
          )}
        </div>
        <div className="settings-privacy-content__consents">
          <DatacLoading isLoading={isFetchingConsents} transparent>
            {consents.length ? (
              <SortableList consents={consents} onSortEnd={onSortEnd} useDragHandle />
            ) : (
              intlPrivacy('no_consents')
            )}
          </DatacLoading>
        </div>
      </div>
      <SettingsPrivacyConsentModal
        isOpened={isAddModalOpened || !!consentToView}
        consent={consentToView}
        onClose={onModalClose}
      />
    </SettingsLayout>
  )
}
